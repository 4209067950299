import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
  ChartOptions,
  Plugin,
} from "chart.js";
import { Line } from "react-chartjs-2";

import ChartDataLabels from "chartjs-plugin-datalabels";

import styles from "./SeparationLineChart.module.scss";

import { SeparationLineChartDataItem } from "../../types";
import { NoChartData } from "../NoChartData";
import { AnyObject } from "chart.js/types/basic";
import { Icons } from "../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface SeparationLineChartProps {
  data: SeparationLineChartDataItem[];
  mySeparation: number;
}

const labelColor = "#B0BBCB";
const degreesOfSeparationColor = "#56C329";
const dashedLineColor = "#DDE2E7";

const tickColors = [labelColor, labelColor, labelColor, labelColor, labelColor, labelColor];

export const SeparationLineChart: React.FC<SeparationLineChartProps> = ({ data, mySeparation }) => {
  if (data.length === 0) {
    return <NoChartData />;
  }

  const mySepIdx = data.findIndex(item => item.label === mySeparation);

  const min = data[0].label;
  const max = data[data.length - 1].label;

  const space = (max - min) * 0.15;

  const labels = data.map(item => {
    return item.label;
  });

  const values = data.map(({ value }) => value);

  const pointBackgroundColor: any = [];
  const pointBorderColor: any = [];

  labels.forEach(value => {
    if (value !== mySeparation) {
      pointBackgroundColor.push("transparent");
      pointBorderColor.push("transparent");
    } else {
      pointBackgroundColor.push(degreesOfSeparationColor);
      pointBorderColor.push("white");
    }
  });

  const chartData = () => {
    return {
      labels,
      datasets: [
        {
          data: values,
          borderColor: degreesOfSeparationColor,
          fill: true,
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const height = context.chart.height;
            const gradientBg = ctx.createLinearGradient(0, 0, 0, height);
            gradientBg.addColorStop(0.2, "rgba(223,243,214,255)");
            gradientBg.addColorStop(0.8, "rgba(255,255,255,1)");
            return gradientBg;
          },
          pointBackgroundColor,
          pointBorderColor,
          pointBorderWidth: 4,
          pointRadius: 6.5,
        },
      ],
    };
  };

  const options: ChartOptions<"line"> = {
    elements: {
      point: {
        radius: 20,
        pointStyle: "circle",
      },
      line: {
        tension: 0.4,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: { display: false },
        offset: true,
      },
      x: {
        min,
        suggestedMax: max,
        type: "linear",
        ticks: {
          stepSize: 1,
          color: tickColors,
          autoSkip: true,
          font: {
            size: 13,
          },
          callback(val) {
            if (
              (val === min && mySeparation - min >= space) ||
              val === mySeparation ||
              (val === max && max - mySeparation >= space)
            )
              return `${this.getLabelForValue(val as number)} %`;
          },
        },
        grid: {
          display: false,
          drawBorder: true,
        },
        offset: false,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const image = new Image();

  image.src = `/icons/${Icons.chartMarker}.svg`;

  const plugins: Plugin<"line", AnyObject>[] = [
    {
      id: "lineChart",
      beforeDatasetDraw: (chart): void => {
        const ctx = chart.ctx;
        const xAxis = chart.scales.x;
        const t = chart.getDatasetMeta(0).data[mySepIdx];
        ctx.save();
        xAxis.ticks.forEach((value, index) => {
          if (value.value === mySeparation) {
            const x = xAxis.getPixelForTick(index);
            ctx.strokeStyle = dashedLineColor;
            ctx.setLineDash([5, 5]);
            ctx.strokeRect(x, t.y, 0, chart.chartArea.bottom - t.y);
            ctx.drawImage(image, t.x - image.width / 2, t.y - image.height * 1.5);
          }
        });
        ctx.restore();
      },
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.lineChartContainer}>
        <Line className={styles.lineChart} data={chartData()} options={options} plugins={plugins} />
      </div>
    </div>
  );
};
